class PinsLoader {
  constructor() {
    this.container = $('body');
    this.container.on('click', '[data-behavior^="load-pins"]', this.loadPins);
  }
};

PinsLoader.prototype.loadPins = function(e) {
  e.preventDefault();
  const message = $(this).data('message');
  const path = $(this).data('path');
  const selectize = $(`#pins-for-${message}`);
  const loader = $('<span>Loading pins…</span>');
  $(this).remove();
  selectize.before(loader);
  return $.get(path, response => {
    loader.remove();
    return new PinTokenSelect(selectize, JSON.parse(response.selected_pin_tokens), JSON.parse(response.suggested_pin_tokens), response.autocomplete_pin_path, response.pin_path);
  });
};

$(() => {
  return Page.pinsLoader = new PinsLoader();
});
