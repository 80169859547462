class TimelineEntrySummaryToggle {
  constructor() {
    this.container = $('body');
    this.container.on('click', '[data-behavior="toggle-timeline-entry-summary"]', this.toggleSummaryAndContent);
  }
};

TimelineEntrySummaryToggle.prototype.toggleSummaryAndContent = function(e) {
  e.preventDefault();
  const timelineEntry = $(this).closest(".timeline-entry");
  const contentSummary = timelineEntry.find(".timeline-entry__content-summary");
  const content = timelineEntry.find(".timeline-entry__content");
  const arrow = timelineEntry.find(".timeline-entry__arrow");
  if (contentSummary.is(":hidden")) {
    contentSummary.toggleClass("hidden");
    content.toggleClass("hidden");
    arrow.removeClass("timeline-entry__arrow--collapse");
  } else {
    contentSummary.toggleClass("hidden");
    content.toggleClass("hidden");
    return arrow.addClass("timeline-entry__arrow--collapse");
  }
};

$(() => {
  return Page.timelineEntrySummaryToggle = new TimelineEntrySummaryToggle();
});
