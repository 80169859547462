// Email & SMS handling
const max_sms_length = 160;

function enable_message_form() {
  const form = $('form#new_message');
  form.on('submit', function(e) {
    tinymce.triggerSave() // Hack to enable edits to TinyMCE textareas on forms loaded via AJAX
  });

  form.find(".message-recipient input[type=checkbox]").click(function() {
    $(this).parent().toggleClass("inactive");
    if ($("form#new_message .message-recipient:not(.inactive)").length == 0) {
      $("form#new_message #message_submit").attr("disabled",true)
    } else {
      $("form#new_message #message_submit").attr("disabled",false)
    }
  })

}

function enable_email_form() {
  enable_message_form();

  var new_email = $('form#new_message');
  new_email.find(".show-cc-bcc").click(function() {
    email = new_email.find(".message");
    link = $(this)
    link.hide();
    fieldClass = ""
    if (link.hasClass("cc")) {
      fieldClass = "cc"
    } else {
      fieldClass = "bcc"
    }
    email.find('.cc-bcc.' + fieldClass).show().find('input').focus();
    email.find(".cc-bcc-spacer").hide();

    if (email.find('.cc-bcc.cc').is(":visible") && email.find('.cc-bcc.bcc').is(":visible")) {
      email.find('.show-cc-bcc-links').closest('tr').hide();
    }

    return false;
  });
}

function enable_sms_form(sms_field, not_in_composer) {
  if (not_in_composer != true) { enable_message_form() }
}

export { enable_email_form, enable_sms_form };
