function FieldAddition() {}

FieldAddition.prototype.addFields = function(e) {
    e.preventDefault();
    var target = $(e.currentTarget);
    var time = new Date().getTime();
    var regexp = new RegExp(target.data('id'), 'g');
    var newContent = $(target.data('fields').replace(regexp, time));
    newContent.insertBefore(target.parent());
};

FieldAddition.prototype.removeFields = function(e) {
    e.preventDefault();
    e.stopPropagation();
    var target = $("#" + $(e.currentTarget).data("target"));
    target.remove();
};

$(function() {
    var fieldAddition = new FieldAddition();
    $(document).on("click", ".add_fields", fieldAddition.addFields);
    $(document).on("click", ".delete_fields", fieldAddition.removeFields);
});
