$(function() {
  var copyToInput = function(e) {
    e.preventDefault();
    contentsId = $(e.target).data("contents-id");
    targetId = $(e.target).data("target-id");
    contents = $("#" + contentsId);
    target = $("#" + targetId);
    text = contents.html();
    text = text.replace(/<br\/*>/g, "\n");      // convert <br> tags to line feeds
    text = text.replace(/[ \t]+(\n)/g, "$1");   // remove leading whitespace before line feeds
    text = text.replace(/(\n)[ \t]+/g, "$1");   // remove trailing whitespace after line feeds
    text = text.replace(/(\n{2})\n+/g, "$1");   // replace 3 or more line feeds with 2 line feeds
    text = text.replace(/([ \t])[ \t]+/g, " "); // replace instances of multiple tab or space chars with a single space char
    text = text.trim();                         // remove leading and trailing whitesapce
    target.val(text).focus();
    autosize.update(target);
  };
  $(document).on("click", "[data-behavior='copy-to-input']", copyToInput);
});
